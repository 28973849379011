import React from 'react'

const AccountContext = React.createContext({})

const { Provider: AccountProvider, Consumer: AccountConsumer } = AccountContext

const withAccount = Component => props => {
  return <AccountConsumer>{data => <Component {...props} {...data} />}</AccountConsumer>
}

export { AccountProvider, AccountConsumer, withAccount }

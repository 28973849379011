class Subscriber {
  listeners = []
  subscriptions = []

  subscribe = listener => {
    const subscription = {
      unsubscribe: () => {
        const i = this.subscriptions.indexOf(subscription)
        if (i >= 0) {
          this.subscriptions.splice(i, 1)
          this.listeners.splice(i, 1)
        }
      },
    }

    this.subscriptions.push(subscription)
    this.listeners.push(listener)
    return subscription
  }

  dispatch = alert => {
    this.listeners.forEach(listener => listener(alert))
  }

  reset = () => {
    this.listeners = []
    this.subscriptions = []
  }
}

export default Subscriber
